<template>
  <div class="pt-2">
    <div class="grid xl:grid-cols-2 gap-4 mb-4">
      <div class="">
        <div :style="`min-height:` +  contentHeight + `px; overflow: initial;`" class="min-h-min bg-white cust_card p-4">
          <div>
            <div class="p-2">
              <p class="label_css">Template Name</p>
              <input disabled
              class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800 opacity-60 cursor-not-allowed" 
              placeholder="Template Name"
              v-model="emailTemplateData.templateName"
              >
            </div>
            <div class="p-2">
              <p class="label_css">Subject</p>
              <input
              :class="eTempDataErr.templateSubjectErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
              class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
              placeholder="Subject"
              v-model="emailTemplateData.emailSubject"
              >
              <p class="text-error heading-6">{{eTempDataErr.templateSubjectErr}}</p>
            </div>
            <div class="grid xl:grid-cols-2">
                <div class="p-2">
                  <p class="label_css">From Name</p>
                  <input 
                  :class="eTempDataErr.fromNameErr ? 'border border-error' : 'border border-gray-500 focus:border-gray-800'"
                  class="outline-none  text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 " 
                  placeholder="From Name"
                  v-model="emailTemplateData.fromName"
                  >
                  <p class="text-error heading-6">{{eTempDataErr.fromNameErr}}</p>
                </div>
                <div class="p-2">
                  <p class="label_css">From Email</p>
                  <input disabled
                  class="outline-none cursor-not-allowed opacity-60 text-gray-900 text-sm rounded  block w-full pl-2 p-2.5 h-12 heading-4 border border-gray-500 focus:border-gray-800" 
                  placeholder="From Email"
                  v-model="emailTemplateData.fromEmail"
                  >
                </div>
            </div>
            <div>
              <div class="p-2">
              <Editor v-model="emailTemplateData.emailMessage" :appendValue="appendValue" style="margin-top:4px;"></Editor>
            </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-white p-4 cust_card">
          <div class="py-1 text-text1 heading-3">
            Please click tags shown below to add in template
          </div>
          <hr class="bottom_space">
          <div class="grid xl:grid-cols-2" wrap>
            <div v-for="(data, index) in entityMergeFieldList" :key="index" class="p-2 pt-0">
              <span class="heading-4 text-text1 font-semibold">{{data.entityName}}</span>
              <div class="pt-2">
                <p class="" v-for="(item, i) in data.mergeFieldList" :key="i">
                  <span @click="addTags(item.fieldSlug)" class="text-primary cursor-pointer heading-5">{{item.displayName}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
    </div>
     <footer class="pb-4">
        <div class="bg-white text-right rounded p-2 cust_card">
          <div class="flex justify-end gap-2">
            <button class="list_top_card_btn font-bold bg-gray-200 cta-text text-text1 py-2 px-4 mr-2 rounded-3xl" @click="canceladdTemplate()">Discard</button>
            <button class="list_top_card_btn font-bold  bg-primary cta-text text-white py-2 px-4 rounded-3xl" @click="addNewEmailTemplate()">Save</button>
          </div>
          </div>
     </footer>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
import Editor from '@/View/components/editor.vue'
export default {
  components: {
    Editor
  },
  data () {
    return {
      mainEntityId: 0,
      mainEntitySlug: '',
      contentHeight: 0,
      appendValue: '',
      moduleEntityId: null,
      moduleSlugName: null,
      entityMergeFieldList: [],
      emailTemplateData: {
        emailTemplateId: 0,
        entityId: 0,
        fromEmail: "",
        fromName: "",
        templateSlug: "",
        templateName: "",
        emailMessage: "",
        emailSubject: "",
        emailTo: "",
        emailCc: "",
        emailBcc: "",
        templateDisplayOrder: 0,
        isTemplateActive: true,
      },
      eTempDataErr: {
        templateSubjectErr: '',
        fromNameErr: ''
      },
    }
  },
  created () {
    window.addEventListener('resize', this.resizeWindowHandler())
  },
  watch: {
    emailTemplateData: {
      handler() {
        if (this.emailTemplateData.emailSubject !== '') {
          this.eTempDataErr.templateSubjectErr = ''
        }
        if (this.emailTemplateData.fromName !== '') {
          this.eTempDataErr.fromNameErr = ''
        }
      },
      deep: true
    },
  },
  mounted () {
    document.title = 'Edit emailTemplate'
    this.moduleEntityId = this.$route.params.emailTemplateId
    this.moduleSlugName = this.$route.params.slug
    this.addTemplateRequiredDetails()
  },
  methods: {
    addTemplateRequiredDetails () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
      ADMINAPI.getEmailMergeFieldList(
        this.moduleSlugName,
        response => {
          let list = response.Data.entityMergeFieldList
          for (let i = 0; i < list.length; i++) {
            let str = list[i].entityName
            let finalstr = str.charAt(0).toUpperCase() + str.slice(1)
            console.log('________________', finalstr)
            let obj = {
              entityName: finalstr,
              mergeFieldList: list[i].mergeFieldList
            }
            this.entityMergeFieldList.push(obj)
          }
          this.getEmailTemplateDetails()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    addTags (addValue) {
      this.appendValue = addValue
    },
    addNewEmailTemplate () {
      let errorFound = false
      let data = this.emailTemplateData
      if (data.emailSubject.trim() === '') {
        this.eTempDataErr.templateSubjectErr = (data.emailSubject.trim() === '') ? 'Subject is required' : ''
        errorFound = true
      }
      if (data.fromName.trim() === '') {
        this.eTempDataErr.fromNameErr = (data.fromName.trim() === '') ? 'From Name is required' : ''
        errorFound = true
      }
      if (errorFound !== true) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
        ADMINAPI.updateEmailTemplate(
          data,
          response => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$router.push({name: 'EmailTemplateList', params: {entityId: this.mainEntityId, slug: this.mainEntitySlug}})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
      }
    },
    getEmailTemplateDetails () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading.....'})
      ADMINAPI.getEmailTemplateDetail(
        this.moduleEntityId,
        response => {
          let etDetails = response.Data
          this.mainEntityId = etDetails.entityId
          this.mainEntitySlug = etDetails.entitySlug
          this.emailTemplateData = {
            emailTemplateId: etDetails.emailTemplateId,
            entityId: etDetails.entityId,
            fromEmail: etDetails.fromEmail,
            fromName: etDetails.fromName,
            templateSlug: etDetails.templateSlug,
            templateName: etDetails.templateName,
            emailMessage: etDetails.emailMessage,
            emailSubject: etDetails.emailSubject,
            isTemplateActive: etDetails.isTemplateActive,
          },
          console.log('emailTemplateData', this.emailTemplateData)
          this.$store.dispatch('SetLoader', {status: false, message: 'Loading.....'})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    canceladdTemplate () {
      console.log(';this.moduleSlugName', this.moduleSlugName)
      this.$router.push({name: 'EmailTemplateList', params: {entityId: this.mainEntityId, slug: this.mainEntitySlug}})
    },
    resizeWindowHandler() {
      this.contentHeight = window.innerHeight - 180
    }
  }
}
</script>
